<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="70%" scrollable>
      <template v-slot:activator="{ on }">
        <app-add-button v-bind:module="'coa'"></app-add-button>
      </template>
      <v-form
        ref="form"
        v-model="isvalid"
        lazy-validation
      >
        <v-card tile>
          <v-toolbar
            flat
            dark
            color="primary"
          >
            <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
            <div class="flex-grow-1"></div>
            <v-btn
              icon
              dark
              @click="close"
            >
              <v-icon>close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6 md4>
                  <v-autocomplete
                      v-model="editedItem.acct_group"
                      :items="accountGroup"
                      :rules="[v => !!v || 'Please select account group']"
                      label="Account Group"
                      data-vv-name="accountGroup"
                      required
                      v-on:change="account_group_change"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-autocomplete
                      v-model="editedItem.acct_class"
                      :items="accountClass"
                      :rules="[v => !!v || 'Please select account class']"
                      label="Account Class"
                      data-vv-name="accountClass"
                      required
                      readonly
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>                    
                  <v-autocomplete
                      v-model="editedItem.acct_type"
                      :items="accountType"
                      :rules="[v => !!v || 'Please select account type']"
                      label="Account Type"
                      data-vv-name="accountType"
                      required
                      v-on:change="get_parents"
                  ></v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-autocomplete
                      v-model="editedItem.acct_parent"
                      :items="accountParents"
                      label="Parent"
                      required
                      item-text="acct_desc"
                      item-value="acct_code"
                      :search-input.sync="search"
                      v-on:change="get_account_code"
                      return-object
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field 
                      v-model="editedItem.acct_code"
                      :counter="7"
                      label="Account Code"
                      required
                      maxlength=7
                      numeric
                      readonly
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4>
                  <v-text-field 
                      v-model="editedItem.acct_desc"
                      :counter="250"
                      :rules="acct_desc_Rules"
                      label="Description"
                      required
                      maxlength=250
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6 md4 v-if="editedItem.acct_type == 'S'">
                  <v-autocomplete
                      v-model="editedItem.currency_id"
                      :items="currency"
                      label="Currency"
                      :rules="[v => !!v || 'Please select currency']"
                      required
                      item-text="cur_desc"
                      item-value="id"
                  >
                  </v-autocomplete>
                </v-flex>
                <v-flex xs12 sm6 md4>
                <v-select
                  v-model="editedItem.acct_status"
                  :items="activeStatus"
                  :rules="[(v) => (!!v && v !== 0) || 'Please select Status']"
                  label="Status"
                  data-vv-name="activeStatus"
                  required
                ></v-select>
              </v-flex>
          </v-layout>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" dark @click="save">Save</v-btn>
          <v-btn color="red darken-1" text @click="close">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
    <app-loader></app-loader>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AppAddButtonVue from '../../../partials/AppAddButton.vue';
import AppLoader from '../../../partials/AppLoader.vue';

export default {
	components: {
		'app-add-button': AppAddButtonVue,
		'app-loader': AppLoader
	},
	data: () => ({
		search: null,
		isvalid: false,
		acct_codeRules: [
			v => !!v || 'Account Code is required',
		],
		acct_desc_Rules: [
			v => !!v || 'Account Description',
			v => (v && v.length <= 250) || 'Account Description must be less than 250 characters',
		],
		activeStatus: [
			{ value: '1', text: 'Active' },
			{ value: '0', text: 'Inactive' },
		],
	}),
	computed: {
		...mapGetters({
			coas: 'coa/coas',
			accountGroup: 'coa/accountGroup',
			accountType: 'coa/accountType',
			accountClass: 'coa/accountClass',
			editedItem: 'coa/editedItem',
			defaultItem: 'coa/defaultItem',
			dialog: 'coa/dialog',
			editedIndex: 'coa/editedIndex',
			formTitle: 'coa/formTitle',
			valid: 'coa/valid',
			accountParents: 'coa/accountParents',
			currency: 'currency/currencies'
      
		})    
	}, 
	watch: {
		dialog(newVal){
			if(newVal){
				if(this.formTitle == 'Edit Entry'){
					this.get_parents();
				}
			}
		},
		// editedItem: {
		//   handler: function(newValue){
		//     console.log('New Val : ' + newValue)
		//   },
		//   deep: true
		// }
	},
	mounted(){
		this.$store.dispatch('currency/getCurrencies');
	},
	methods: {
		close () {
			setTimeout(() => {
				this.$refs.form.reset();
				this.$refs.form.resetValidation();
				this.$store.dispatch('coa/setValid',true);
				this.$store.dispatch('coa/setDialog',false);
				this.$store.dispatch('coa/setEditedIndex','-1');
				this.isvalid = false;
			}, 2000);
		},
		save() {
			this.$store.dispatch('coa/setValid',this.$refs.form.validate());
			this.isvalid = this.$refs.form.validate();
			if(this.isvalid){
				delete this.editedItem['updated_at'];
				delete this.editedItem['created_at'];
				delete this.editedItem['edit_date'];
				if (this.editedIndex > -1) {
					this.$store.dispatch('coa/updateCoa',this.editedItem);
				} else {
					this.$store.dispatch('coa/saveCoa',this.editedItem);
				}
				setTimeout(() => {
					this.close();
				}, 3000);
			}
		},
		get_parents(){
			if(this.editedItem.acct_type !== 'S'){
				this.editedItem.currency_id = '';
			}
			if(this.editedItem.acct_type && this.editedItem.acct_group){
				this.$store.dispatch('coa/setAccountParents',{'acct_type': this.editedItem.acct_type,'acct_group':this.editedItem.acct_group});
			}
		},
		get_account_code: function(value){
			if(value){
				this.editedItem.acct_parent = value.acct_code;
				this.editedItem.acct_parent_desc = value.acct_desc;
				this.$store.dispatch('coa/setAccountCode',{'acct_type': this.editedItem.acct_type,'acct_parent': value.acct_code});
			}
		},
		account_group_change(value){
			switch(value){
			case 'A':
			case 'L':
			case 'C':
				this.$store.dispatch('coa/setAccountClass','B');
				break;
			case 'E':
			case 'I':
				this.$store.dispatch('coa/setAccountClass','I');
				break;
			default:
				break;
			}
		}
	}
};
</script>
<template>
	<div>
		<v-data-table
			:headers="headers"
			:items="coas.data"
			sort-by="acct_desc"
			class="elevation-1"
			dense
			:search="search"
			hide-default-footer
			:items-per-page="1000"
		>
			<template v-slot:top>
				<v-toolbar flat color="white">
					<v-toolbar-title>Chart of Accounts</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details></v-text-field>
					<vue-excel-xlsx
						:data="coas.data"
						:columns="columnHeaders"
						:file-name="module_name"
						>
						<v-btn icon alt class="mt-2" :color="coas.data.length=== 0 ? 'gray' : 'primary'" :disabled="coas.data.length===0"><v-icon>mdi-arrow-down-bold-circle</v-icon> </v-btn>
					</vue-excel-xlsx>
				</v-toolbar>
			</template>
			<!-- <template v-slot:item.view_details="{ item }">
				<v-icon color="primary" class="mr-2" small @click="viewItem(item)">add</v-icon>
			</template> -->
			<!-- <option value="0">Year:</option>
		<option v-for="year in years" :value="year">{{ year }}</option> -->
			<template v-slot:item.action="{ item }">
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 3"
				>
					mdi-pencil-off
				</v-icon>
				<v-icon
					color="primary"
					v-else
					class="mr-2"
					small
					@click="editItem(item)"
				>
					edit
				</v-icon>
					<v-icon
					color="grey"
					class="mr-2"
					small
					v-if="role_access < 4"
				>
					mdi-delete-off
				</v-icon>
				<v-icon
					small
					v-else
					color="red"
					@click="deleteItem(item)"
				>
					delete
				</v-icon>
			</template>
			<template v-slot:body.append>
					<tr>
						<td colspan="8">
							<v-layout>
								<v-spacer></v-spacer>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											:disabled="parseInt(coas.current_page)==1"
											@click="changePrevData"
											class="ma-2"
											text
											icon
										>
											<v-icon :disabled="parseInt(coas.current_page)==1">mdi-chevron-left</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
								<v-flex md2 style="padding-top:15px;text-align: center">
									<p class="font-weight-light text--primary body-2">
										Page {{coas.current_page}} ( {{coas.from}} - {{coas.to}} )
									</p>
								</v-flex>
								<v-flex md1>
									<v-layout justify-center>
										<v-btn
											@click="changeNextData"
											class="ma-2"
											:disabled="!coas.next_page_url"
											text
											icon
										>
											<v-icon :disabled="!coas.next_page_url">mdi-chevron-right</v-icon>
										</v-btn>
									</v-layout>
								</v-flex>
							</v-layout>
						</td>
					</tr>
			</template>
		</v-data-table>
		<create-coa v-if="role_access > 1"></create-coa>
	</div>
</template>

<script>
import createCoa from './createCoa.vue';
import { mapGetters } from 'vuex';

export default {
	components: {
		'create-coa': createCoa
	},
	data() {
		return {
			module_name: 'Chart of Account',
			role_access: 1,
			search: '',
			months: [
				'January',
				'February',
				'March',
				'April',
				'May',
				'June',
				'July',
				'August',
				'September',
				'October',
				'November',
				'December'
			]
		};
	},
	computed: {
		...mapGetters({
			coas: 'coa/coas',
			headers: 'coa/headers',
			columnHeaders: 'coa/columnHeaders',
			editedItem: 'coa/editedItem',
			defaultItem: 'coa/defaultItem',
			dialog: 'coa/dialog',
			editedIndex: 'coa/editedIndex',
			valid: 'coa/valid',
			currUser: 'auth/currUser'
		})
	},
	watch: {
		coas: {
			handler(val) {
				val.data.map(det => {
					det.status = (det.acct_status === '1' ? 'Active' : 'Inactive');
				});
			},
			deep: true
		}
	},
	mounted() {
		this.$store.dispatch('coa/getCoas');

		let roles = this.currUser.roleaccess;
		if(roles.length > 0){
			let roleaccess = roles.filter(r => r.module_name === this.module_name);
			if(roleaccess.length > 0){
				this.role_access = roleaccess[0].access_level;
			}
		}
	},
	methods: {
		async editItem(item) {
			this.$store.dispatch('coa/setEditedIndex', this.coas.data.indexOf(item));
			//this.$store.dispatch('coa/setEditedItems', Object.assign({}, item));
			await this.$store.dispatch('coa/getDetails', item.id);
			this.$store.dispatch('coa/setDialog', true);
			this.$store.dispatch('coa/setValid', true);
			this.$store.dispatch('coa/setformTitle', 'Edit Entry');
		},
		deleteItem(item) {
			this.$swal.fire({
				title: 'Delete!',
				text:
				item.acct_desc +
				'(' +
				item.acct_code +
				')' +
				'? You won\'t be able to revert this!',
				type: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Yes, delete it!'
			}).then(result => {
				if (result.value) {
					this.$store.dispatch('coa/setEditedIndex', this.coas.data.indexOf(item));
					this.$store.dispatch('coa/deleteCoa', item);
				}
			});
		},
		changeNextData() {
			var nextPage = this.coas.current_page + 1;
			this.$store.dispatch('coa/getDataPages', nextPage);
		},

		changePrevData() {
			var prevPage = this.coas.current_page - 1;
			this.$store.dispatch('coa/getDataPages', prevPage);
		}
	}
};
</script>

<style scoped>
</style>
<template>
	<div class="home">
		<table-coa></table-coa>
  </div>
</template>

<script>
import tableCoa from '@/components/pages/maint/coa/tableCoa.vue';

export default {
	components: {
		'table-coa': tableCoa,
	} 
};
</script>
